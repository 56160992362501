<template>
  <td v-if="isLoading" colspan="20" />
  <td v-else :colspan="colspan" class="">
    <div class="table-nodata">
      <div>
        <AppIcon name="table" size="56px" is-img-tag />
      </div>
      <div class="table-nodata-title">
        <slot name="title">
          {{ t('component.table.noRecords') }}
        </slot>
      </div>
      <div class="table-nodata-description">
        <slot name="description" />
      </div>
      <slot />
    </div>
  </td>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    colspan: {
      type: Number,
      default: null,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.table-nodata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  padding: 80px 20px;
  height: 100%;
  text-align: center;

  &-title {
    margin-top: 16px;
    font-size: 13px;
    line-height: 1.4;
  }
  &-description {
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.2;
    color: var(--color-black-05);
  }
}
</style>
