<template>
  <TransitionWrapper>
    <div class="main-content-body">
      <FiltersHeader
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        :statuses="statuses"
        :filters="filters"
        @data-loaded="s => $emit('data-loaded', s)"
      >
        <template #top-append>
          <AddButton @click="isCreateModalOpen = true">
            {{ buttonAddText }}
          </AddButton>
        </template>
        <template #status-aside>
          <slot name="status-aside" />
        </template>
      </FiltersHeader>

      <slot name="main">
        <PaginationTable
          :module-name="moduleName"
          :api-path="apiPath"
          :columns="columns"
          :is-info-modal-active="isInfoModalActive"
          @row-click="onRowClick"
        />
      </slot>
    </div>

    <ModalItem
      v-model="isCreateModalOpen"
      v-bind="createParams"
      @update-data="getFreshData"
    >
      <slot name="create" />
    </ModalItem>

    <ModalItem
      v-model="isItemModalOpen"
      v-bind="infoParams"
      @update-data="getFreshData"
    >
      <slot name="item" :item="selectedItem" />
    </ModalItem>
  </TransitionWrapper>
</template>

<script setup>
import { ref } from 'vue';

import AddButton from '@/components/Buttons/AddButton.vue';
import FiltersHeader from '@/components/Modules/DataTable/FiltersHeader.vue';
import PaginationTable from '@/components/Modules/DataTable/PaginationTable.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { usePagination } from '@/composables/usePagination';
import { useBus } from '@/composables/useBus';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

import ModalItem from './ModalItem.vue';

const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  statuses: {
    type: Array,
    default: () => [],
  },
  filters: {
    type: Array,
    default: () => [],
  },
  moduleName: {
    type: String,
    default: '',
  },
  apiPath: {
    type: String,
    default: '',
  },
  eventName: {
    type: String,
    default: '',
  },

  buttonAddText: {
    type: String,
    default: 'Add',
  },

  createParams: {
    type: Object,
    default: () => {},
  },
  infoParams: {
    type: Object,
    default: () => {},
  },
  isInfoModalActive: {
    type: Boolean,
    default: true,
  },
});

defineEmits(['data-loaded']);

const isCreateModalOpen = ref(false);
const isItemModalOpen = defineModel('itemModal', { type: Boolean, default: false });

const { getData } = usePagination(props.apiPath, {
  eventName: props.eventName,
  moduleName: props.moduleName,
  filters: props.filters,
});

getData();

const getFreshData = async () => {
  await getData({ isFreshResponse: true });
  isCreateModalOpen.value = false;
};

const { emitHandler } = useBus();
emitHandler('update-table-data', () => {
  getFreshData();
});

const selectedItem = ref({});

const onRowClick = (item) => {
  if (props.isInfoModalActive) {
    isItemModalOpen.value = true;
    selectedItem.value = item;
  }
};

defineExpose({ onRowClick, getFreshData });

useKeyboardEvent(keys.BACKQUOTE, () => { isItemModalOpen.value = false; });
</script>

<style lang="scss" scoped>
:deep(.table__body .table__row__cell) {
  cursor: pointer;
}

</style>
