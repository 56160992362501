<template>
  <div class="add-button">
    <AppTooltip
      :is-enabled="isDisabled"
      text-align="left"
      width="252px"
    >
      <template #text>
        {{ disabledTooltip }}
      </template>

      <FButton
        no-margin
        :disabled="isDisabled"
        size="small"
        @click="onClick"
      >
        <AppIcon name="plus" size="18px" fill="var(--color-white)" />
        <AppText class="font-medium" :line-height="1.35">
          <slot />
        </AppText>
        <span class="tilde">~</span>
      </FButton>
    </AppTooltip>
  </div>
</template>

<script>
import AppTooltip from '@/components/AppTooltip.vue';

import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

export default {
  name: 'AddButton',
  components: { AppTooltip },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    disabledTooltip: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const onClick = () => {
      emit('click');
    };

    useKeyboardEvent(keys.BACKQUOTE, () => { emit('click'); });

    return {
      onClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  display: inline-block;

  :deep(.el-button--small) {
    padding: 0 !important;

    & > span {
      height: 40px;
      padding: 10px 12px;
      border-radius: 4px;
    }
  }

  .tilde {
    background: var(--color-white-03);
    border: 1px solid var(--color-white-03);
    border-radius: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  :deep(.el-button.is-disabled) {
    .tilde {
      background: #e6e6e6;
      border-color: var(--color-black-005);
      color: var(--color-white)
    }
  }
}
</style>
