<template>
  <div class="currency">
    <AppText
      v-if="!isSuffix"
      v-bind="$attrs"
      :line-height="1.23"
    >
      {{ currencyInfo.symbol }}<template v-if="currencyInfo?.hasArabicStyle">
        &nbsp;
      </template>
    </AppText>
    <NumberFormatter
      :value="value"
      v-bind="$attrs"
      :type="type"
      :format="numberFormat"
      :line-height="1.23"
    />
    <AppText
      v-if="isSuffix"
      v-bind="$attrs"
      :line-height="1.23"
    >
      &nbsp;{{ currencyInfo.short }}
    </AppText>
  </div>
</template>

<script>
import { computed } from 'vue';

import { currency } from '@/composables/useCurrency';
import { currencyList, numberTypes } from '@/common/data';
import { useAdmin } from '@/composables/useAdmin';
import { useSettings } from '@/composables/useSettings';
import { useToken } from '@/composables/useToken';

export default {
  name: 'CurrencyFormatter',
  inheritAttrs: true,
  props: {
    value: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    isAccountCurrency: {
      type: Boolean,
      default: false,
    },
    isSuffix: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: numberTypes.FLOAT2,
    },
    format: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const short = computed(() => {
      let currencyShortName = currencyList[0].short;

      if (props.isAccountCurrency) {
        currencyShortName = currency.value.value;
      } else if (props.name) {
        currencyShortName = props.name;
      }

      return currencyShortName;
    });

    const currencyInfo = computed(() => (currencyList.find((item) => item.short === short.value)));

    const { isAdminRole } = useToken();
    const { numberFormat: numberFormatSettings } = isAdminRole.value ? useAdmin() : useSettings();

    const numberFormat = computed(() => props.format || numberFormatSettings.value);

    return { currencyInfo, numberTypes, numberFormat };
  },
};
</script>

<style lang="scss" scoped>
.currency {
  display: inline-flex;
  align-items: center;
}
</style>
