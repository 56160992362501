<template>
  <div class="input-number">
    <div v-if="!noLabel" style="margin-bottom: 9.6px;">
      <AppText>{{ label }}</AppText>
    </div>
    <div class="d-flex">
      <FButton is-icon type="text" size="large" @click="decrease">
        <AppIcon name="minus" size="18px" is-img-tag />
      </FButton>
      <div class="numeric-value">
        <slot :value="modelValue">
          <AppText size="17px" class="font-medium">
            {{ modelValue }}
          </AppText>
        </slot>
      </div>
      <FButton is-icon type="text" size="large" @click="increase">
        <AppIcon name="plus" size="18px" is-img-tag />
      </FButton>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from 'vue';

import { useModel } from '@/composables/useModel';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 12,
    },
  },
  setup(props, { emit }) {
    const { value } = useModel(props, emit);

    const decrease = () => {
      if (value.value > 1) {
        value.value--;
      }
    };

    const increase = () => {
      if (value.value < props.max) {
        value.value++;
      }
    };

    watch(computed(() => props.max), () => {
      if (value.value > props.max) {
        value.value = props.max;
      }
    });

    return { value, decrease, increase };
  },
});
</script>

<style scoped lang="scss">
.numeric-value {
  width: 84px;
  height: 44px;
  @include flex-center;

  background: var(--color-white);
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  margin: 0 4px;
}
</style>
