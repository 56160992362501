<template>
  <div class="loader-wrap">
    <svg class="loader" width="50" height="50" viewBox="0 0 50 50">
      <circle
        cx="25"
        cy="25"
        r="23"
        fill="none"
        stroke="transparent"
        stroke-width="2"
      />
      <circle
        class="percent"
        cx="25"
        cy="25"
        r="23"
        fill="none"
        stroke="var(--color-primary)"
        stroke-width="2"
        :pathLength="pathLength"
      />
    </svg>

    <div class="inner">
      <slot>
        {{ value.toFixed(0) }}
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
  percent: {
    type: Number,
    default: 0,
  },
});

const value = computed(() => (props.percent - 100));
const pathLength = ref(100);

onMounted(() => {
  // To prevent empty circle on init
  if (props.percent <= 0) {
    pathLength.value = 0;
    setTimeout(() => {
      pathLength.value = 100;
    }, 2000);
  }
});
</script>

<style lang="scss" scoped>
.loader-wrap {
  width: 50px;
  height: 50px;
  position: relative;

  .loader {
    transform: rotate(-90deg);

    .percent {
      stroke-dasharray: 100;
      stroke-dashoffset: v-bind('value');
      stroke-linecap: round;
      @include transition-base(stroke-dashoffset);
    }
  }

  .inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include flex-center;
  }
}
</style>
