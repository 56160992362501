<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="480px"
    v-bind="$attrs"
  >
    <slot />
  </SmoothModal>
</template>

<script>
import SmoothModal from '@/components/SmoothModal';

import { useModel } from '@/composables/useModel';

export default {
  name: 'ModalItem',
  components: {
    SmoothModal,
  },
  emits: ['update-data'],
  setup(props, { emit }) {
    const { value: isModalOpen } = useModel(props, emit);

    return {
      isModalOpen,
    };
  },
};
</script>
