<template>
  <div class="alert d-flex align-items-center">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.alert {
  background: var(--color-black-003);
  border-radius: 4px;
  padding: 12px 17px;
  margin-bottom: 8px;
}
</style>
