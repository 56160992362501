<template>
  <div
    class="dot"
    :style="styles"
  />
</template>

<script>
import { reactive } from 'vue';

export default {
  name: 'DotSeparator',
  props: {
    size: {
      type: String,
      default: '4px',
    },
    color: {
      type: String,
      default: 'var(--color-black)',
    },
    margin: {
      type: String,
      default: '0 10px',
    },
  },
  setup(props) {
    const styles = reactive({
      '--size': props.size,
      '--color': props.color,
      '--margin': props.margin,
    });

    return { styles };
  },
};
</script>

<style scoped lang="scss">
.dot {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background: var(--color);
  margin: var(--margin);
}
</style>
