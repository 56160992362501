<template>
  <FButton
    size="x-small"
    type="transparent"
    class="refresh"
    :class="{ disabled: isDisabled }"
    @click="onUpdate"
  >
    <AppIcon
      :name="icon"
      size="12px"
      :class="{ 'is-rotating': isRotating && isAnimated }"
      style="margin-right: 6px;"
    />
    <AppText size="12px" variant="div" mt="1px">
      <slot>
        {{ t('common.refresh') }}
      </slot>
    </AppText>
  </FButton>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    default: 'rotate-ccw-reload',
  },
  isAnimated: {
    type: Boolean,
    default: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['action']);

const isRotating = ref(false);
const onUpdate = () => {
  isRotating.value = true;

  setTimeout(() => {
    isRotating.value = false;
    if (!props.isDisabled) {
      emit('action');
    }
  }, 200);
};
</script>

<style scoped lang="scss">
.refresh {
  height: 28px;
  padding: 0 14px;
  background: var(--color-white);
  color: var(--color-black);
  border: 1px solid var(--color-black-01);
  box-shadow: 0 2px 5px var(--color-black-01);
  border-radius: 22px;

  @include transition-base;

  :deep(.icon path) {
    fill: var(--color-black);
    @include transition-base;
  }

  &:hover {
    color: var(--color-primary);
    background: var(--color-white);
    :deep(.icon path) {
      fill: var(--color-primary);
    }
  }

  &.disabled {
    background-color: var(--color-EBEBEB);
    box-shadow: none;
    border: none;
    cursor: not-allowed;

    :deep(.icon path) {
      fill: var(--color-black-02);
    }

    :deep(.text) {
      color: var(--color-black-02);
    }
  }
}

.is-rotating {
  transition: transform linear 300ms;
  transform: rotate(-360deg);
}
</style>
