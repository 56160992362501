<template>
  <div class="check-wrapper">
    <AppIcon name="check" size="18px" is-img-tag />
  </div>
</template>

<style scoped lang="scss">
.check-wrapper {
  @include flex-center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: var(--color-black-005);
  margin: 40px 0 20px;
}
</style>
