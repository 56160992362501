<template>
  <div
    class="body bottom-fade-in"
    :class="{
      'has-no-data': !data.length,
    }"
  >
    <div class="table-wrapper">
      <CustomScroll :style="`padding-top: 20px; max-height: 678px; max-height: ${$attrs?.style['max-height']};`">
        <table class="data">
          <template v-for="(history, index) in data" :key="history._id">
            <tr class="history-item">
              <td width="38px" style="color: #949494;">
                <div class="d-flex align-items-center justify-content-between">
                  <span>
                    {{ data.length - index }}
                  </span>
                  <DotSeparator color="#cccccc" />
                </div>
              </td>
              <td width="162px">
                <div class="d-flex align-items-center">
                  <DateFormatter
                    style="color: #949494; white-space: nowrap;"
                    :format="dateFormat"
                    :date="history.updatedAt"
                    :line-height="1"
                  />
                  <DotSeparator color="#cccccc" />
                </div>
              </td>
              <td>
                <AppText :line-height="1" class="font-medium" variant="div">
                  <template v-if="['ACTIVATED', 'REACTIVATED'].includes(history.action)">
                    {{ t(`action.${history.action}`, { currency: getCurrency(history.data.currency) }) }}
                  </template>
                  <template v-else-if="['RECEIVED', 'SIGHTED'].includes(history.action)">
                    {{ t(`action.${history.action}`, { amount: history.data.amount, currency: getCurrency(history.data.currency) }) }}
                  </template>
                  <template v-else-if="['INVITED'].includes(history.action)">
                    {{ t(`action.${history.action}`, { admin: history.data.admin }) }}
                  </template>

                  <template v-else>
                    {{ t(`action.${history.action}`) }}
                    <template v-if="history.data?.plan && history.data?.cycle">
                      {{ t('common.to') }} <span class="is-capitalized font-medium"> {{ history.data?.plan.toLowerCase() }} ({{ history.data?.cycle.toLowerCase() }}) </span>
                    </template>
                    <template v-if="history.action === 'Renewed' && history.data?.cycle && history.data?.term">
                      {{ getTerm(history) }}
                    </template>
                    <template v-if="history.data?.credit">
                      ${{ history.data?.credit }}
                    </template>
                    <template v-if="history.data?.newName && history.data?.oldName">
                      {{ t('common.name') }}
                    </template>
                  </template>
                </AppText>

                <TooltipCopy
                  v-if="history.data?.transactionId || history.data?.address"
                  :text="history.data?.transactionId || history.data?.address"
                  is-icon-hidden
                >
                  <AppText
                    variant="div"
                    size="12px"
                    mt="10px"
                    mr="30px"
                    mb="-2px"
                    opacity="0.4"
                    :line-height="1.5"
                    class="d-flex align-items-center"
                    style="line-break: anywhere;"
                  >
                    <template v-if="history.data?.transactionId">
                      {{ t('component.historyBlock.hash') }} - {{ history.data?.transactionId }}
                    </template>
                    <template v-if="history.data?.address">
                      {{ t('component.historyBlock.address') }} - {{ history.data?.address }}
                    </template>
                  </AppText>
                </TooltipCopy>

                <AppText
                  v-if="history.journalNote || history.data?.note || history.data?.newName"
                  variant="div"
                  size="12px"
                  mt="12px"
                  mr="30px"
                  opacity="0.4"
                  :line-height="1.3"
                  style="line-break: anywhere;"
                >
                  <template v-if="history?.journalNote">
                    <TooltipCopy
                      :text="history.journalNote"
                      is-icon-hidden
                    >
                      {{ t('component.historyBlock.journalEntry') }} - {{ history.journalNote }}
                    </TooltipCopy>
                  </template>
                  <template v-else-if="history.data?.note">
                    <TooltipCopy
                      :text="history.data.note"
                      is-icon-hidden
                    >
                      {{ history.data.note }}
                    </TooltipCopy>
                  </template>
                  <template v-if="history.data?.newName && history.data?.oldName">
                    {{ t('common.from') }} "{{ history.data?.oldName }}" {{ t('common.to').toLowerCase() }} "{{ history.data?.newName }}"
                  </template>
                </AppText>
              </td>
            </tr>
          </template>
          <NoData v-if="!data.length" class="no-data">
            <template #title>
              {{ t('component.historyBlock.noHistory') }}
            </template>
          </NoData>
        </table>
      </CustomScroll>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';
import NoData from '@/components/MainTable/NoData.vue';

import { getCurrency } from '@/utils/functions';

export default defineComponent({
  components: {
    DateFormatter: defineAsyncComponent(() => import('@/components/Helpers/DateFormatter.vue')),
    DotSeparator,
    TooltipCopy,
    NoData,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD (h:mm A)',
    },
  },
  setup() {
    const periods = {
      MONTHLY: 'month',
      YEARLY: 'year',
    };

    const getTerm = (history) => {
      const period = periods[history.data?.cycle];
      return `for ${history.data?.term} ${period}${history.data?.term > 1 ? 's' : ''}`;
    };

    return { getTerm, getCurrency };
  },
});
</script>

<style scoped lang="scss">
.body {
  border-top: 1px solid var(--color-black-01);
  min-height: 400px;

  flex: 1;
  display: flex;
  flex-direction: column;

  &.has-no-data {
    .data {
      height: 400px;
    }
  }
}

.table-wrapper {
  flex: 1;
  max-height: inherit;
  padding: 0 0 0 40px;
}

.data {
  font-size: 13px;
  width: 100%;
}
.no-data {
  padding: 0px 180px;
}

.history-item {
  border-bottom: 1px solid var(--color-black-01);
  td {
    padding: 15px 0;
  }
}
</style>
